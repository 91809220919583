import Title from '../components/Title'
import SmallMenu from '../components/SmallMenu/SmallMenu'
import Config from '../config/config.json'
import Line from '../components/Line/Line'
import LineItem from '../components/Line/LineItem'

import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from 'usehooks-ts'
import { useLoader } from '../hooks/useLoader'

function Contact() {
  const { t } = useTranslation()
  const loader = useLoader('map')
  useDocumentTitle(`${t('tiles.contact')} | Příběh těla`)

  return (
    <Title goBack title={t('tiles.contact')}>
      <div className="flex flex-col px-8 md:px-32">
        <div className="flex flex-col gap-20 my-10">
          <LineItem
            align="left"
            indent={2.7}
            pointSrc="/svg/circle-user.svg"
            className="lg:w-3/4"
          >
            <div className="flex flex-col text-xl">
              <span>{Config.name}</span>
              <span>
                {t('contact.phoneNumber')}: {Config.phone}
              </span>
              <span>E-mail: {Config.email}</span>
              <span>{Config.address.name}</span>
            </div>
          </LineItem>
          <LineItem
            title={t('contact.blocks.0.title')}
            align="right"
            indent={2}
            pointSrc="/svg/circle-point.svg"
            className="lg:w-3/4"
          >
            {t('contact.blocks.0.description')}
          </LineItem>
          <LineItem
            title={t('contact.blocks.1.title')}
            align="left"
            indent={2.3}
            pointSrc="/svg/circle-bulb.svg"
            className="lg:w-3/4"
          >
            {t('contact.blocks.1.description')}
          </LineItem>
        </div>
        <div className="flex items-center justify-center mt-16">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2567.438495191786!2d15.275769375297381!3d49.94687642349588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c410f40ddf0a9%3A0x1fa5ba6838c10e39!2zxIzDoXNsYXZza8OhIDE5OSwgMjg0IDAxIEt1dG7DoSBIb3JhIDEtS2FybG92!5e0!3m2!1scs!2scz!4v1701080554994!5m2!1scs!2scz"
            title="map"
            height="450"
            className="border-0 w-full relative"
            allowFullScreen
            onLoad={() => loader.solve('map')}
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <SmallMenu except={['contact', 'reservations']} />
        <Line src="/svg/line.svg" />
      </div>
    </Title>
  )
}

export default Contact
