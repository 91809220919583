import { ReactNode } from 'react'

interface HeaderItemProps {
  href: string
  target?: string
  children?: ReactNode
}

function HeaderItem({ href, target, children }: HeaderItemProps) {
  return (
    <a href={href} target={target || '_self'}>
      {children}
    </a>
  )
}

export default HeaderItem
