import Config from '../../config/config.json'
import HeaderItem from './HeaderItem'
import HeaderOpenHours from './HeaderOpenHours'

import { AiOutlineCompass, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'

function Header() {
  return (
    <div className="relative z-20 flex items-center justify-between px-8 sm:px-32 w-full h-12 bg-primary text-secondary shadow-xl">
      <HeaderItem href={Config.address.link} target="_blanc">
        <span className="lg:inline hidden">{Config.address.name}</span>
        <AiOutlineCompass className="text-2xl lg:hidden text-secondary" />
      </HeaderItem>

      <HeaderItem href={`tel:${Config.phone}`}>
        <span className="md:inline hidden">{Config.phone}</span>
        <AiOutlinePhone className="text-2xl md:hidden" />
      </HeaderItem>

      <HeaderItem href={`mailto:${Config.email}`}>
        <span className="md:inline hidden">{Config.email}</span>
        <AiOutlineMail className="text-2xl md:hidden" />
      </HeaderItem>

      <HeaderOpenHours />
    </div>
  )
}

export default Header
