import Footer from './components/Footer'
import Header from './components/Header/Header'

import { Outlet } from 'react-router-dom'
import Loader from './components/Loader'
import ScrollToTop from './components/ScrollToTop'
import { LoaderProvider } from './providers/LoaderProvider'

function App() {
  return (
    <LoaderProvider>
      <div className="w-screen min-h-[100svh] flex flex-col justify-between overflow-hidden">
        <div className="flex flex-col">
          <Header />
          <Outlet />
        </div>
        <Footer />
      </div>
      <Loader />
      <ScrollToTop />
    </LoaderProvider>
  )
}

export default App
