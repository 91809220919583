import React from 'react'
import LanguageSelector from './LanguageSelector'
import Image from './Image'

import { useNavigate, useLocation, Link } from 'react-router-dom'

interface TitleProps {
  goBack?: boolean
  title?: string
  children?: React.ReactNode
}

function Title({ title, goBack, children }: TitleProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const handleGoBack = () => {
    if (location.key !== 'default') navigate(-1)
    else navigate('/', { replace: true })
  }

  return (
    <div className="relative flex flex-col w-full pt-10 z-10">
      <div className="flex flex-col w-full mb-16 md:mb-20">
        <div className="grid grid-cols-3 w-full justify-between items-center px-8 md:px-32">
          {goBack ? (
            <div onClick={handleGoBack} className="cursor-pointer">
              <Image
                src="/svg/back.svg"
                alt="vratit-se"
                className="w-12 sm:w-16"
              />
            </div>
          ) : (
            <div></div>
          )}
          <Link to="/" className="flex justify-center">
            <Image
              src="/svg/logo.svg"
              alt="logo-pribehtela"
              className="w-24 md:w-32"
            />
          </Link>
          <div className="flex justify-end">
            <LanguageSelector />
          </div>
        </div>
        {title ? (
          <div className="flex justify-center items-center mt-16 w-full">
            <div className="relative w-auto">
              <span className="relative text-secondary text-2xl z-30">
                {title}
              </span>
              <Image
                src="/svg/circle.svg"
                alt="circle"
                className="absolute bottom-0 left-[-.5rem] w-10"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {children}
    </div>
  )
}

export default Title
